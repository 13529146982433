export const white = '#f8f8f8',
  black = '#313131',
  blue = '#679AD6',
  blue_light = '#9ACDFF',
  blue_dark = '#4E81BD',
  light = '#e9e9e9',
  dark = '#363636',
  primary = '#00d1b2',
  link = '#3273dc',
  info = '#209cee',
  success = '#23d160',
  warning = '#ffdd57',
  danger = '#ff3860';
